@import "../../assets/sass/variables";

.ask-auto-header {
    &-title {
        color: $darkPurpleColor;
        display: flex;
        align-items: center;
        gap: 10px;

        * {
            color: $darkPurpleColor;
        }
    }

    &-actions {
        display: flex;
        gap: 10px;
        align-items: center;


        .action {
            cursor: pointer;
            border-radius: 5px;
            // padding: 8px;
            // border: 1px solid $textSecondaryColor;
            box-sizing: border-box;

            display: flex;
            align-items: center;
            gap: 5px;

            padding: 3px 5px;
            color: $baseTextColor;

            &-text {
                color: $darkPurpleColor;
                font-size: 12px;
            }

            &:hover {
                background-color: $lightgray;
              * {
                color: $bgBaseColor;
              }
            }
        }
    }
}

.ask-auto {
    &-button {
        border-radius: 50%;
        background-color: $darkBlue;
        padding: 8px;
        transition: all 300ms ease;
        cursor: pointer;
        color: $white;
        font-size: 18px;

        &:hover {
            background-color: $white;
            color: $darkBlue
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: stretch;
        height: 100%;

        .ant-drawer-body {
            display: flex;
            flex-direction: column;
        }

        .responses {
            flex-grow: 1;

            display: flex;
            flex-direction: column-reverse;
            overflow-y: auto;
            align-items: stretch;
            gap: 10px;

            margin-bottom: 10px;

            &-single {
                display: flex;
                flex-direction: column;
            }

            .new-question {
                margin: auto;
                text-align: center;
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                p {
                    color: $textSecondaryColor;
                    font-size: 12px;
                }
            }

        }


        form {
            justify-self: flex-end;
            display: flex;
            align-items: stretch;
            gap: 20px;

            border-radius: 5px;
            border: 1px solid $borderColor;
            padding: 10px;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);


            input {
                flex-grow: 1;
                outline: none;
                border: none;

                &:disabled {
                    background-color: transparent;
                    cursor: not-allowed;
                }
            }

            button {
                border-radius: 50%;
                padding: 8px;
                transition: all 300ms ease;
                cursor: pointer;
                color: $darkBlue;
                border: none;
                background-color: transparent;

                &:hover {
                    background-color: $borderColor;
                }

                &:disabled {
                    background-color: $borderColor;
                }

            }
        }

    }

    &-warning {
        color: $textSecondaryColor;
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
    }

    &-prompts-container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &-prompts {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin: 20px 0;
        text-align: center;

        &-single {
            border: 1px solid $lightgray;
            border-radius: 10px;
            padding: 10px;
            color: $textSecondaryColor;
            cursor: pointer;

            &:hover {
                background-color: $lightgray;
            }

            p {
                font-size: 12px;
            }

            i {
                font-size: 9px;
            }

        }

        &-toggle {
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 20px 20px 0 0;
            padding: 3px 25px;
            border: 1px solid $lightgray;
            font-size: 12px;
            transition: all 250ms ease;

            &:hover {
                background-color: $lightgray;
            }

            &-short {
                top: 15px;
            }
        }
    }
}