@import "../../../../assets/sass/variables";

.ask-auto-question {
    display: flex;
    gap: 20px;
    padding: 20px;

    .ask-auto-question-img {
        border: 1px solid $textSecondaryColor;
        border-radius: 5px;
        padding: 5px;

        img {
            object-fit: cover;
            width: 20px;
        }
    }
}