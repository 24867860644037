@import "../../assets/sass/variables";

.attachment {
  .ant-upload .ant-upload-btn {
    border-radius: 3px;
  }

  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-top: none;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 0.3s;
    padding: 10px;
  }

  .ant-upload-list-item {
    background-color: #FFFFFF;
    height: 100%;
    margin: 0px !important;
  }

  &-content {
    display: flex;
    gap: 20px;

    &-text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-top: 8px;
    }
  }

  &-uploadbtn {
    display: flex;
    justify-content: space-between;
  }
}

.dashboard-table {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  td {
    cursor: default;
  }

  thead {
    padding-right: 8px;
  }

  &-preview {
    color: #3232A6;
    display: flex;
    justify-content: center;

    &-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }

  &-actions {
    color: #3232A6;
    display: flex;
    justify-content: center;
    &-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .actions-tile {
    width: 100px;
  }

  &-page-count {
    text-align: center !important;
    width: 150px !important;
  }

  &-status {
    text-align: center !important;
    max-width: 180px !important;
  }

  &-small {
    text-align: center !important;
    max-width: 100px !important;
  }

  &-mid-size {
    text-align: center !important;
    max-width: 250px !important;
  }

  .table-doc-id {
    min-width: 30% !important;
    color: $darkBlue !important;
    cursor: pointer;
  }

  .ant-table-tbody {
    max-height: calc(100vh - 180px) !important;
  }
}

.pdf-viewer {
  min-width: 40%;
  background: #FBFBFB;
  position: relative;

  &-closeicon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 16px;
    cursor: pointer;
  }

  .ant-progress {
    margin: 11px;
    width: 94%;
  }
}