@import "../../../../assets/sass/variables";

.ask-auto-response {
    border-radius: 5px;
    background-color: $lightgray;
    padding: 20px;

    display: flex;
    gap: 20px;
    align-items: flex-start;

    .ask-auto-response-img {
        background-color: $darkPurpleColor;
        border-radius: 5px;
        padding: 5px;

        img {
            width: 20px;
            object-fit: contain;
            max-width: 20px;
        }
    }

    .response {

        &-text {
            font-size: 16px;
            white-space: pre-line;
            margin-bottom: 10px;
        }

        .sources-container {
            ul {
                color: $darkBlue;
                display: flex;
                flex-direction: column;
                gap: 5px;

                li {
                    font-size: 12px;
                    list-style-type: disc;
                    margin-left: 20px;
                    margin-top: 5px;
                }
            }
        }

        .actions {
            display: flex;
            gap: 20px;
            align-items: center;
            color: $textSecondaryColor;
            font-size: 16px;

            .anticon {
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }
        }
    }
}