@import "../../assets/sass/variables";

.case-details-wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;

  .react-pdf__Document {
    height: 76vh;
    overflow: auto;
  }

  .tabView {
    flex: 2;
    margin: 20px 0;
    background: #F5F5F6;
    padding: 0px 20px 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .tab-container {
      flex-grow: 1;
    }

    .ant-tabs {
      flex-grow: 1;
    }

    .ant-tabs-content, .ant-tabs-tabpane-active {
      height: 100%;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #3232a6;
    }

    .ant-tabs-nav {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #f5f5f6;
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-nav-list {
      justify-content: space-between;
      width: 100%;
    }

    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      height: 5px;
      background: none !important;
    }

    .ant-tabs-tab {
      border-bottom: 2px solid $textSecondaryColor;
      margin: 0 !important;

      &-active {
        border-bottom: 2px solid $darkBlue;
      }
    }

    &-title {
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #ccc;
        font-size: 12px;
        margin-right: 15px;
      }
    }
  }
}