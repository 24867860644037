@import "../../assets/sass/variables";

.guidelines {
  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-text-container {
    flex-grow: 1;
    background: #FFFFFF;
    padding: 20px;
    font-size: 16px;
    overflow: auto;
  }

  h3 {
    font-size: 16px;
  }

  &-codes {
    font-weight: 500;
    border: 1px solid $borderColor;
    border-radius: 3px;
    padding: 10px 20px;
    margin-top: 10px;
  }

  &-guidelines {
    font-weight: 700;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    padding: 10px 20px;
    margin-top: 10px;
  }

  &-compliance {
    border-bottom: 1px solid $borderColor;
    padding: 10px 20px;
  }

  &-checkboxes {
    margin-top: 30px;

    label {
      padding: 5px;
      border: 1px solid $borderColor;
      margin-right: 10px;
      margin-top: 10px;
      border-radius: 3px;
    }
  }

  &-downicon {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      position: absolute;
      top: 28px;
      color: $textSecondaryColor;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &-upicon {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      position: absolute;
      bottom: -10px;
      color: $textSecondaryColor;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &-downicon2 {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      color: #9e9e9e;
      background-color: #fff;
      border-radius: 50%;
      bottom: 0px;
    }
  }

  &-upicon2 {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    span {
      color: $textSecondaryColor;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &-callFlags {
    border: 1px solid $borderColor;
    padding: 10px;
    margin-top: 10px;
    height: 50vh;
    overflow: auto;

    .loading-icon {
      min-height: 100px;
      padding: 50px 0;
    }
  }
}