@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.result-pdf {
  &.ant-card {
    margin-bottom: 20px;
    padding: 20px;

    .slick-prev,
    .ant-carousel .slick-prev:hover {
      left: -10px;
    }

    .ant-carousel .slick-next,
    .ant-carousel .slick-next:hover {
      right: -10px;
    }

    .result-occurrences {
      text-align: center;
      color: variables.$baseTextColor;

      &-title {
        @include font_size_color(12px, variables.$titleColor, 600, null);

        .edit-icon {
          margin-left: auto;
          // font-weight: 400;
          cursor: pointer;
          color: variables.$linkColor;
        }
      }
    }
  }

  a {
    color: variables.$baseTextColor;
  }
}

.result-occurrences {
  text-align: center;
  color: variables.$baseTextColor;

  &-content {
    padding: 5px;
    border-radius: 4px;
    border-radius: 4px;
    background: variables.$baseColor;
    display: flex !important;
    gap: 0.5rem;
    justify-content: flex-start;

    p {
      font-size: 12px;
    }

    &.active {
      color: variables.$linkColor;
      border: 1px solid variables.$linkColor;

      p {
        color: variables.$linkColor;
      }
    }
  }

  &-title {
    margin: 16px 0 10px 0;
    font-family: Font-Semibold;
    @include font_size_color(12px, variables.$titleColor, null, null);
    .edit-icon {
      margin-left: auto;
      // font-weight: 400;
      cursor: pointer;
      color: variables.$linkColor;
    }
    span {
      font-family: Font-Regular;
    }
  }

  &-count {
    font-size: 12px;
    font-family: Font-Bold;
    // padding: 6px 8px;
    border-radius: 50%;
    background: variables.$linkColor;
    color: variables.$baseColor;
    width: 24px;
    height: 24px;
    display: flex;
    line-height: 2;
    text-align: center;
  }
}

.tab-active {
  p {
    color: variables.$linkColor;
  }
}
