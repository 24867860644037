@import '../../../../assets/sass/variables';

.case-settings-container {
    display: grid;
    grid-template-columns: 0.75fr 3.25fr;
    min-height: 30vh;
    gap: 10px;
    .ant-anchor-wrapper {
        height: max-content;

        .ant-anchor-link-title {
            color: $baseTextColor !important;
            &-active {
                color: black !important;
            }
        }
    }

    .case-settings {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input-container {
            h4 {
                all: revert;
                font-weight: 500;
                margin: 0 0 10px 0;
            }
            textarea {
                outline: none;
                border: 1px solid $lightgray;
                border-radius: 4px;
                width: 100%;
                padding: 10px;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            gap: 10px;
        }

    }

}