@import "../../assets/sass/variables";

.settings-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    
    .ant-collapse {

        &-header {
            background: rgba(0, 0, 139, 0.182);
        }

        &-content-box {
            background: $white;
            border: 1px solid #eef4f8;
            box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
            padding: 20px !important;
        }
    }
}