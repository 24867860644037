@import "../../assets/sass/variables";

.authorization-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 16px;
  height: 100%;
  &-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
  }
  
  section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }

  .display-auth {
    flex-grow: 2;
    min-height: 30vh;
    overflow: auto;
    color: $baseTextColor;

    h3 {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid lightgray;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 40vh;
      white-space: pre-line;
    }
  }

  .summary-actions-container {
    display: flex;
    gap: 20px;
    min-height: 25vh;
    align-items: stretch;

    .actions {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      margin: 10px 0;

      &-single {
        border-bottom: 1px solid $textSecondaryColor;
        padding-bottom: 4px;
        color: $textSecondaryColor;
        cursor: pointer;
        width: max-content;
      }

      .active-tab {
        border-bottom: 1px solid $blue;
        color: $blue;
      }
    }

    .generator {
      &-container {
        flex-grow: 1;
        color: $baseTextColor;
        overflow: auto;
      }

      &-content {
        min-height: 20vh;
        max-width: 100%;
        overflow: auto;
      }
    }
  }

  .case-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: transparent;
    align-items: center;

    .actions {
      display: flex;
      gap: 10px;
    }

    button {
      font-size: 14px;
    }
  }
}
